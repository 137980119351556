// @ts-nocheck
import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import { animated, useSpring } from '@react-spring/web'



const Modal = props => {
  const {
    visible,
    onClose,
    height,
  } = props;

  if(!visible) return null;

  const appRoot = document.getElementsByTagName('body')[0];
  const styles = useSpring({
    from: { height: 0 },
    to: { height },
  });

  const Component = (
    <div class={classnames("fixed top-0 z-10 left-0 w-full h-full", { hidden: !visible})}>
      <div className="overlay z-20 w-full h-full" style={{ backgroundColor: 'rgba(0, 0, 0, .7)' }} />
      <animated.div style={{ ...styles }} className="z-100 fixed w-full left-0 bottom-0 bg-white pt-10" onClick={onClose}>
        <div className="relative">
          <div className="absolute flex items-center justify-center bg-white rounded-full cursor-pointer" onClick={onClose} style={{ border: '5px solid #3D3D3D', height: 52, width: 52, top: -72, left: 'calc(50% - 26px)'}}>
            <img src="/icons/close.svg" className="mb-0" />
          </div>
        </div>
        {props.children}
      </animated.div>
    </div>
  )
  return ReactDOM.createPortal(
    Component,
    appRoot
  )
}

export default Modal;
