import React from "react"
import ProductView from "../components/ProductView";
const basicTemplate = props => {
  const { pageContext: { product } } = props;
  return (
    <ProductView
      product={product}
    />

  )
}
export default basicTemplate
