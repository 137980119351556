import React, { useState } from 'react';
import classnames from 'classnames';
import Modal from '../Modal';

const Caracterisitcs = props => {
  const {
    caracteristics
  } = props;
  const [selectedCaracteristic, setSelectedCaracteristic] = useState(undefined);
  console.log(caracteristics, !!selectedCaracteristic)

  return (
    <div className={`grid grid-cols-2 gap-4 px-4`}>
      {caracteristics.map((caracteristic, i) => (
        <div onClick={() => setSelectedCaracteristic(caracteristic)} key={i} className={classnames("cursor-pointer border-gray border rounded-md flex items-center px-4 py-4")}>
          <div className="flex justify-center items-center mr-3" style={{ width: 30 }}>
            <img src={caracteristic.icon} className="mb-0" />
          </div>
          {caracteristic.title}
        </div>
      ))}
      <Modal
        visible={!!selectedCaracteristic}
        onClose={() => setSelectedCaracteristic(undefined)}
        height={120 + 18 + 50 + (selectedCaracteristic?.items?.length || 0) * 30}
      >
        <div className="text-center mb-4 font-bold text-xl" style={{ height: 30 }}>
          {selectedCaracteristic?.title}
        </div>
        {(selectedCaracteristic?.items || []).map(spec => (
          <div className='flex text-base px-2' style={{ height: 30 }}>
            <span className="font-bold">
              {spec.title}
            </span>
            <span className="ml-auto">
              {spec.value}
            </span>
          </div>
        ))}
        <div style={{ height: 45 }} className="rounded-md bg-indigo-500 text-center mx-2 py-2 cursor-pointer text-white my-5 transition-colors hover:bg-indigo-600">
          Revenir à la fiche produit
        </div>
      </Modal>
    </div>
  )
}

export default Caracterisitcs;
