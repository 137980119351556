import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import Divider from '../Divider';

const DeclinationOptions = props => {
  const {
    options,
    grid = 2
  } = props;

  const [selectedOption, setOption] = useState(options[0]);
  useEffect(() => {
    setOption(options[0]);
  }, [options])

  return (
    <>
      <div className={`grid grid-cols-2 gap-4 px-4`} >
        {
          options.map(option => (
            <div key={option.title}
              onClick={() => setOption(option)}
              className={classnames("flex align-items-center justify-center border py-3 rounded-md cursor-pointer color-black", {
                'border-gray': selectedOption.title !== option.title,
                'border-dark-blue': selectedOption.title === option.title,
                'border-2': selectedOption.title === option.title,
              })}
            >
              {option.title}
            </div>
          ))
        }
      </div>
      {selectedOption?.options && selectedOption.options.length > 0 && (
        <>
          <Divider />
          <DeclinationOptions
            options={selectedOption.options}
          />
        </>
      )}
    </>
  )
};

export default DeclinationOptions;
