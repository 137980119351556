import React from 'react';

const ProductSpec = props => {
  const {
    spec
  } = props;
  return (
    <>
      <h3 className="text-center mt-7">Fiche technique</h3>
      <div className="grid grid-cols-2 gap-4 px-10">
        {spec.mains && spec.mains.length > 0 && (
          <div>
            {spec.mains.map(spec => (
              <div className="mb-4 flex">
                <div style={{ width: 30 }}><img src={spec.icon} className="mb-0" /></div>
                <div>
                  <div className="font-bold">
                    {spec.title}
                  </div>
                  <div>
                    {spec.value}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {spec.others && spec.others.length > 0 && (
          <div>
            {spec.others.map(spec => (
              <div className="mb-3">
                <div className='text-xs font-bold'>{spec.title}</div>
                <div className="text-xs">{spec.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  )
}

export default ProductSpec;
