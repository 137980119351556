import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { StaticImage } from "gatsby-plugin-image"

const ProductImages = props => {
  const {
    images
  } = props;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="my-7 mb-14">
      <Slider {...settings}>
        {images.map(image => (
          <div key={image}>
            <img
              className="mx-auto"
              src={image}
              width={300}
              alt="image"
              style={{ marginBottom: `1.45rem` }}
            />
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default ProductImages;
