import React from 'react';

const ColorSwatch = props => {
  const {
    colors,
    value,
    onClick,
  } = props;
  return (
    <div className="flex justify-center">
      {colors.map(color => (
        <div
          key={color.title}
          className="relative mx-1 cursor-pointer"
          onClick={() => onClick(color.hex)}
        >
          <div className="absolute rounded-full"
            style={{
              backgroundColor: value === color.hex ? '#000' : '#FFF',
              top: 0,
              left: 0,
              height: 34,
              width: 34,
              zIndex: 1,
              boxShadow: 'inset 0px 1px 1px rgba(0, 0, 0, 0.25)',
            }}
          />
          <div
            style={{ backgroundColor: color.hex, height: 30, width: 30, margin: 2, zIndex: 2 }}
            className="rounded-full relative"
          />
        </div>
      ))}
    </div>
  )
}

export default ColorSwatch;
