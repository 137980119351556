import React from 'react';
import DeclinationOptions from '../DeclinationOptions';

const DeclinationView = props => {
  const {
    declination
  } = props;
  return (
    <>
      <div className="text-gray text-center mt-7 mb-7">
        Choisissez vos options
      </div>
      {declination.options && declination.options.length > 0 && (
        <DeclinationOptions
          options={declination.options}
        />
      )}
    </>
  )
};

export default DeclinationView;
