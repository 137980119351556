import React, { useState } from 'react';
import Caracterisitcs from '../Caracteristics';
import ColorSwatch from '../ColorSwatch';
import DeclinationOptions from '../DeclinationOptions';
import DeclinationView from '../DeclinationView';
import Divider from '../Divider';
import ProductImages from '../ProductImages';
import ProductSpec from '../ProductSpec';

const ProductView = props => {
  const {
    product
  } = props;
  const [declination, setSelectedDeclination] = useState(product.declinations[0]);

  const onColorChange = (color) => {
    const declination = product.declinations.find(d => d.color.hex === color);
    setSelectedDeclination(declination);
  }

  return (
    <div className="pb-7">
      <div className="flex-auto flex-col py-5">
        <h1 className="text-center mb-1">
          {product.product_title}
        </h1>
        <h3 className="text-center text-gray mb-0">
          {product.product_subtitle}
        </h3>
      </div>
      {declination && declination.images && (
        <ProductImages
          images={declination.images}
        />
      )}
      <ColorSwatch
        colors={(product.declinations || []).map(d => d.color)}
        onClick={onColorChange}
        value={declination?.color?.hex}
      />
      {declination && declination.options && (
        <DeclinationView
          declination={declination}
        />
      )}
      {product.options && product.options.length > 0 && (
        <>
          <Divider />
          <DeclinationOptions
            options={product.options}
            grid={3}
          />
        </>
      )}
      {product.caracteristics && product.caracteristics.length > 0 && (
        <>
          <Divider />
          <Caracterisitcs caracteristics={product.caracteristics} />
        </>
      )}
      {product.spec && (
        <ProductSpec spec={product.spec} />
      )}
      <div id="portalContainer"></div>
    </div>
  )
}

export default ProductView;
